import React from "react";
import { Helmet } from "react-helmet";

export const PixelsAndAnalytics = () => {
  return (
    <Helmet>
      <script src="/js/analytics.js"></script>
      <script async src="https://stats.tazeros.com/v3.js"></script>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7890142.js"></script>
    </Helmet>
  );
};