import { cn } from '@bem-react/classname';
import React from 'react';
import './section.scss';


export const SECTION_COLOR = {
    DarkGray: 'dark-gray',
    DarkBlue:'dark-blue',
    LightBlue: 'light-blue',
    Violet: 'violet',
    Pink: 'pink',
    White: 'white',
    Black: 'black',
}

const Section = ({centered=false, custom='', color=SECTION_COLOR.White, smallBottom, children}) => {
    const classItem = cn('Section');

    return (
        <div className={classItem('', {centered, color, custom, smallBottom})}><div className={classItem('Wrapper')}>{children}</div></div>
    );
}

export default Section;

